import i18n from "@/core/i18n/i18n";

const { t } = i18n.global;
export function isAnyRouteHasNotFilterProp(list) {
  const isList = [];

  function findRouteHasNotFilter(findingList, hasRoute = false) {
    // eslint-disable-next-line consistent-return
    findingList.forEach((step) => {
      if (hasRoute) {
        const isRouteDefault = step.name.includes("Default");
        const isStepLengthValid = isRouteDefault ? true : step.filter.length > 0;
        isList.push(Array.isArray(step.filter) && isStepLengthValid);
      } else {
        isList.push(true);
      }

      if (Array.isArray(step.routes) && step?.routes?.length > 0) {
        return findRouteHasNotFilter(step.routes, true);
      }
      if (Array.isArray(step.steps) && step?.steps?.length > 0) {
        return findRouteHasNotFilter(step.steps, false);
      }
    });
  }

  findRouteHasNotFilter(list);

  return isList.every((x) => x);
}
